.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  margin-bottom: 10px;
  width: 400px;
}

.image {
  width: 300px;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorMessage {
  color: #ff8889;
  margin-top: 10px;
  margin-left: 160px;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.firstNameRoot {
  width: calc(34% - 9px);
  margin-top: 10px;
}

.lastNameRoot {
  width: calc(66% - 9px);
  margin-top: 10px;
}

.email {
  margin-top: 10px;
}
.modern {
  margin-top: 10px;
}

.button {
  margin-top: 20px;
}
.locationAddress {
  margin-top: 10px;
}
.label {
  margin-top: 10px;
}
.hidden {
  display: none;
}
