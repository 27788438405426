@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 15px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1300px) / 2) 82px 59px;
  }
}
.mainProfile {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 15px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 48px calc((100% - 1300px) / 2) 82px 59px; */
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  padding: 0 24px;
  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  @media only screen and (max-width: 1024px) {
    /* For mobile phones: */
    position: static;
    top: 0;
    z-index: var(--zIndexTopbar);
    flex-direction: column; /* Change flex direction to column */
    align-items: flex-start; /* Align items to the start (left) */
    padding-left: 1rem;
    /* Add some padding for better visibility */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a subtle box-shadow */
  }
  @media (--viewportLarge) {
    padding: 48px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    width: unset;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px 0 82px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
