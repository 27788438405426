/* @import '../../styles/propertySets.css'; */
@import '../../styles/customMediaQueries.css';
.select {
  margin-top: 1rem;
  width: auto;
  @media (--viewportMedium) {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.normalSelect {
  width: 150px;
  margin-top: 18px;
  font-size: 14px;
  color: var(--matterColor);
  @media (--viewportMedium) {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.normalSelectLandingPage {
  width: 100px;
  margin-top: 10px;
  font-size: 14px;
  color: var(--matterColor);
  @media (--viewportMedium) {
    color: white;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.label {
  font-size: 14px;
}
.menuContent {
  padding: 0.5rem 0.5rem;
  width: 150px;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid var(--colorGrey100);
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--marketplaceColor);
  }
}
.dataContainer {
  padding: 0.5rem;
}
.changeLanguageForm {
  display: flex;
  align-items: center;
  align-self: baseline;
  font-size: 14px;
  font-weight: 500;
}
.menuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 25px 12px 20px 12px;
  cursor: pointer;
  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}
.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}
.downIcon {
  font-size: 20px;
  font-weight: 400px;
}
.desktop {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.mobile {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.main {
  display: flex;
}
