.root {
}

.inputError {
  border: var(--borderErrorField);
  border-radius: 6px;
}
/* .inputRootClass{
  border:1px solid red;
} */

.parentLabel{
  display:flex;
  /* flex-direction:; */
  

}