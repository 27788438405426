@import '../../styles/customMediaQueries.css';
.error {
  color: red;
}
.affiliationButton {
  width: 100%;
  min-width: 200px;
  @media (--viewportMedium) {
    width: 240px;
  }
}
