@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.locationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--colorWhite);
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0;
    padding-left: 0.5rem;
    width: 100%;
    border-radius: 35px;
  }
}

.keywordField,
.locationField {
  width: 100%;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--colorGrey200);
  @media (--viewportMedium) {
    width: auto;
    margin-bottom: 0;
    border-bottom: unset;
  }
}

.keywordField {
  border-bottom: 1px solid var(--colorGrey200);

  @media (--viewportMedium) {
    width: 100%;
    border-bottom: unset;
    /* border-right: 1px solid var(--colorGrey200); */
  }
}

.searchButton {
  display: block;
  /* border-radius: 0px 30px 30px 0px; */
  background-color: var(--marketplaceColor);
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;

  @media (--viewportMedium) {
    width: 50%;
    border-radius: 0px 30px 30px 0px;
    margin-top: 0;
  }

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.locationAutocompleteInput,
.keywordInputRoot {
  text-align: center;
  border: unset;
  box-shadow: unset;
  &:hover,
  &:focus,
  &:active {
    border: unset;
    box-shadow: unset;
  }
}
.keywordInputRootPlaceholder {
  color: var(--colorGrey200);
  text-align: center;
  border: unset;
  box-shadow: unset;
  &:hover,
  &:focus,
  &:active {
    border: unset;
    box-shadow: unset;
  }
}

.locationAddress {
  width: auto;
  padding: 0.5rem;

  @media (--viewportMedium) {
    width: 45%;
  }
}

/* .borderLine {
  margin: 0;
  padding: 0;
} */

.desktopicon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    font-size: 50px;
    margin-left: 0.5rem;
    width: 90px;
  }
}

.mobileIcon {
  display: none;
  font-size: 25px;
  margin-top: 13px;
  margin-right: 0.5rem;
  cursor: pointer;

  @media (--viewportMedium) {
    display: none;
  }
}
.iconContainer {
  display: flex;
  width: 100%;
  align-items: center;
  border-right: none;
  @media (--viewportMedium) {
    border-right: 1px solid var(--colorGrey200);
  }
}
.locationIcon {
  font-size: 20px;
  margin-right: 0.5rem;
  /* border-right: 1px solid var(--colorGrey200); */
}
