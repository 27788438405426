.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  /* border-bottom-color: var(--failColor); */
}

.textarea {
}

.disabled {
  /* border-bottom-color: var(--matterColorAnti); */
  color: var(--matterColorAnti);
  cursor: not-allowed;
  &:hover {
    border-bottom-color: var(--matterColorAnti) !important;
  }
}
.parentLabel{
  display:flex;
}