.orderError {
  color: red;
}
.checkoutButton {
  margin-bottom: 1rem;
  background-color: transparent;
  color: var(--colorGrey800);
  border: 1px solid var(--colorGrey700);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  &:hover {
    background-color: transparent;
    color: var(--colorGrey800);
    border: 1px solid var(--colorGrey900);
    border-radius: 5px;
  }
  &:focus {
    background-color: transparent;
    color: var(--colorGrey800);
    border: 1px solid var(--colorGrey900);
    border-radius: 5px;
  }
}
.logoImage {
  width: 80px;
  height: 30px;
  border: 1px solid var(--colorGrey100);
  border-radius: 4px;
}
.text {
  margin: 0;
}
.spinner {
  width: 28px;
  height: 28px;

  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}
